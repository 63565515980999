
class qFileInBlod {
    constructor() {
    }
  
    showImage(image, qyClass) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.addEventListener("load", (e) => {
          const getImage = document.querySelector(
            `.${qyClass}`
          );
          getImage.src = e.target.result;
        });
    }
  }
  
  export default new qFileInBlod();
  
  